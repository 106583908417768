import React, { useState } from 'react';
import NavBar from './Components/NavBar';
import Body from './Components/Body';

import './App.css';

export default function App() {
  const [ searchText, setSearchText ] = useState();

  return (
    <div className="App">
      <NavBar searchText={searchText} setSearchText={setSearchText} />
      <Body searchText={searchText} />
    </div>
  );
}
