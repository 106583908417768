import React from 'react';
import DataSetsForceDirectedGraph from '../DataSetsForceDirectedGraph';

import './Body.css';

export default function Body({searchText}) {
  return (
    <div className='app-body'>
      <DataSetsForceDirectedGraph />
    </div>
  );
}
