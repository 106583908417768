import React from 'react';

import './InputRangeSlider.css';

export default function InputRangeSlider(props) {
  const {
    min,
    max,
    step,
    text,
    isVisible,
    value,
    onChangeValue,
  } = props;

  const handleSliderChanged = (e) => {
    onChangeValue(e);
  };

  if (!isVisible) return (<></>);

  return (
    <>
      <input className='slider' 
        id='relatedSlide'
        type='range'
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleSliderChanged}
      />
      <div>{text}: {value}</div>
    </>
  );
}
