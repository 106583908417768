import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';

import InputRangeSlider from '../InputRangeSlider';
import ResetButton from '../ResetButton';

import {
  loadAreas,
  loadDatasetsFromArea,
  loadRelatedDatasets,
  initNodes,
  initLinks,
  generateGraph,
} from './helpers';

import './DataSetsForceDirectedGraph.css';

export default function DataSetsForceDirectedGraph() {
  const defaultRootType = 'domain';
  const defaultNodeType = 'area';
  const defaultRootNode = {
    id: 1,
    name: 'Select a Category',
    nodeType: defaultRootType,
  };
  const [ nodeData, setNodeData ] = useState([]);
  const [ rootNode, setRootNode ] = useState(defaultRootNode);
  const [ rootType, setRootType ] = useState(defaultRootType);
  const [ nodeType, setNodeType ] = useState(defaultNodeType);
  const [ sliderValue, setSliderValue ] = useState(25);
  const [ viewLimit, setViewLimit ] = useState(30);
  const [ showLimitSlider, setShowLimitSlider ] = useState(true);
  const [ showScoreSlider, setShowScoreSlider ] = useState(false);

  const onSliderChange = (e) => {
    setSliderValue(parseInt(e.target.value, 10));
  };

  const onViewLimitChanged = (e) => {
    console.log(`view limit changed:: ${e.target.value}`);
    setViewLimit(parseInt(e.target.value, 10));
  };

  const processSelectArea = async (newRootNode) => {
    newRootNode.isRootNode = true;
    const { items } = await loadDatasetsFromArea(newRootNode);
    setNodeData(items);
    setRootNode(newRootNode);
    setRootType(newRootNode.nodeType);
    setShowLimitSlider(true);
    setNodeType('dataset');
  };

  const processSelectDataset = async (newRootNode) => {
    newRootNode.isRootNode = true;
    console.log(`newRootNode:: ${JSON.stringify(newRootNode, null, 2)}`);
    const datasets = await loadRelatedDatasets(newRootNode);
    setNodeData(datasets);
    setRootNode(newRootNode);
    setRootType(newRootNode.nodeType);
    setShowLimitSlider(true);
    setShowScoreSlider(true);
    setNodeType('dataset');
  };

  const handleSingleClickNode = (event, d) => {
    console.log('singleClickNode');
  };

  const handleDoubleClickNode = (event, d) => {
    console.log('doubleClickNode');
    if (d.rootNode) return;
    if (d.nodeType === 'area') {
      processSelectArea(d).catch((err) => {
        console.log(`Error selecting area: ${err}`);
      });
    } else if(d.nodeType === 'dataset') {
      processSelectDataset(d).catch((err) => {
        console.log(`Error selecting data set: ${err}`);
      });
    }
  };

  const resetVisualization = async () => {
    setRootNode(defaultRootNode);
    setRootType(defaultRootType);
    setNodeType(defaultNodeType);
    setShowScoreSlider(false);
    initVisualization();
  };

  const initVisualization = async () => {
    const { taxonomyAreas } = await loadAreas();
    if (taxonomyAreas.length < 1) return;
    setNodeData(taxonomyAreas);
  };

  useEffect(() => {
    initVisualization();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log(`useEffect generateGraph`);
    const offset = 0;
    d3.selectAll('.link').remove();
    d3.selectAll('.node').remove();
    d3.selectAll('.linklabel').remove();
    d3.selectAll('.linkPath').remove();
    let filteredNodeData = [];
    if (rootType === 'dataset') {
      console.log(`filtering datasets :: ${nodeData.length}`);
      filteredNodeData = nodeData.filter((d) => {
        return d.relatednessScore >= sliderValue;
      });
    } else {
      console.log(`rootType:: ${rootType}`);
      filteredNodeData = nodeData;
    }
    filteredNodeData = filteredNodeData.slice(offset, offset+viewLimit+1);
    console.log(`filteredNodeData.length::${filteredNodeData.length}`);
    const nodes = initNodes(rootNode, filteredNodeData, nodeType);
    const links = initLinks(rootNode, filteredNodeData);
    generateGraph(rootNode, nodes, links, handleSingleClickNode, handleDoubleClickNode);
    // eslint-disable-next-line
  }, [rootNode, rootType, nodeData, nodeType, sliderValue, viewLimit]);

  return (
    <div className='visualization-root'>
      <svg 
        id='viz-svg'
        className='viz-svg'
      />
      <div className='reset-btn-div'>
        <ResetButton 
          resetFn={resetVisualization}
          rootType={rootType}
        /> 
      </div>
      <div className='view-limit-div'>
        <InputRangeSlider
          min={10}
          max={50}
          step={10}
          text='Limit Nodes'
          isVisible={showLimitSlider}
          value={viewLimit}
          onChangeValue={onViewLimitChanged}
        />
      </div>
      <div className='min-score-slider-div'>
        <InputRangeSlider
          min={0}
          max={100}
          step={5}
          text='Minimum Score'
          isVisible={showScoreSlider}
          value={sliderValue}
          onChangeValue={onSliderChange}
        />
      </div>
    </div>
  );
}
