import React from 'react';

import './ResetButton.css';

export default function ResetButton(props) {
  const {
    resetFn,
    rootType,
  } = props;

  if (rootType === 'domain') {
    return (
      <></>
    );
  }

  return (
    <div className='reset-button-div'>
      <button className='reset-button' 
        onClick={resetFn}
      >
        Reset
      </button>
    </div>
  );
}
