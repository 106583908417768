import React from 'react';

import logo from '../../../img/cdot_logo_full.png';

import './NavBar.css';

export default function NavBar({searchText, setSearchText}) {
  const searchTextChanged = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div className='navbar'>
      <div className='navbar-logo'>
        <img src={logo} alt='logo' />
      </div>
      <div className='navbar-title'>
        CDOT Data Discovery Tool
      </div>
      <div className='navbar-search'>
        <input 
          type='text'
          className='navbar-search-input'
          placeholder='Search for a data set...'
          value={searchText}
          onChange={searchTextChanged}
        />
      </div>
    </div>
  );
}
