import * as d3 from 'd3';
import axios from 'axios';

const loadAreas = async () => {
  console.log('loading areas');
  const taxonomyDomainDataEntityId = 'ckcqdo38n4bo80705baiv3gmb';
  const endpoint = `/api/taxonomy/domains/${taxonomyDomainDataEntityId}/areas`;
  const { data } = await axios.get(endpoint);
  return data;
};

const loadDatasetsFromArea = async (rootNode) => {
  console.log('loading datasets');
  const datasetId = 'ckcqdre5d4ik20705dh0wyc51';
  const limit = 500;
  const endpoint = `/api/taxonomy/areas/${rootNode.id}/items?itemTypeId=${datasetId}&limit=${limit}`;
  const { data } = await axios.get(endpoint);
  return data.data;
};

const loadRelatedDatasets = async (rootNode) => {
  const limit = 400;
  const endpoint = `/api/visualizations/relatedDatasets/${rootNode.id}?limit=${limit}`;
  const { data } = await axios.get(endpoint);
  return data;
};

const adaptNode = (nodeData, nodeType) => {
  const { id, name } = nodeData;
  return {
    id,
    name,
    nodeType,
  };
};

const adaptLink = (nodeData, source) => {
  const relatednessScore = nodeData.relatednessScore ? parseInt(nodeData.relatednessScore, 10) : 0;
  return {
    source,
    target: nodeData.id,
    relatednessScore,
  };
};

const initNodes = (rootNode, data, nodeType) => {
  let nodes = [rootNode];
  data.forEach((d) => {
    if (d.id !== rootNode.id) {
      nodes.push(adaptNode(d, nodeType));
    }
  });
  return nodes;
};

const initLinks = (rootNode, data) => {
  let links = [];
  data.forEach((d) => {
    links.push(adaptLink(d, rootNode.id));
  });
  return links;
};

const drag = (simulation) => {
  function dragstarted(event, d) {
    if (!event.active) simulation.alphaTarget(0.3).restart();
    d.fx = event.x
    d.fy = event.y;
  }
  function dragged(event, d) {
    d.fx = event.x
    d.fy = event.y;
  }
  function dragended(event, d) {
    if (!event.active) simulation.alphaTarget(0);
    d.fx = null;
    d.fy = null;
  }
  return d3.drag()
    .on('start', dragstarted)
    .on('drag', dragged)
    .on('end', dragended);
};

const placeNodes = (d) => {
  if (d.index < 11 ) {
    return 150;
  } else if (d.index < 31) {
    return 225;
  } else if (d.index < 64) {
    return 325;
  }
};

const generateSimulation = (nodes, links, onTick) => {
  return d3.forceSimulation(nodes)
    .force('link', d3.forceLink(links).id(d => d.id).distance(d => placeNodes(d)))
    .force('charge', d3.forceManyBody().strength(-800))
    .force('x', d3.forceX(0))
    .force('y', d3.forceY(0))
    .on('tick', onTick);
};

const generateGraph = (rootNode, nodes, links, handleSingleClickNode, handleDoubleClickNode) => {
  console.log('generating graph');

  const width = window.innerWidth;
  const height = window.innerHeight;

  const simulation = generateSimulation(nodes, links, onTick);

  const svg = d3.select('svg')
    .attr('viewBox', [-width / 2, (-height / 2)+50, width, height-100]);

  function onTick() {
    circle
      .attr('cx', d => d.x)
      .attr('cy', d => d.y);

    text
      .attr('x', d => d.x)
      .attr('y', d => d.y);

    link
      .attr('x1', d => d.source.x)
      .attr('y1', d => d.source.y)
      .attr('x2', d => d.target.x)
      .attr('y2', d => d.target.y);

    linkPaths.attr('d', d => 'M ' + d.source.x + ' ' + d.source.y + ' L ' + d.target.x + ' ' + d.target.y);
  }

  const link = svg.selectAll('.link')
    .data(links)
    .enter()
    .append('line')
    .attr('class', 'link')
    .attr('stroke-width', d => d.relatednessScore ? d.relatednessScore / 2 : 2)
    .attr('stroke-opacity', 0.15)
    .attr('stroke', d => '#202020');

  const linkPaths = svg.selectAll('.linkPath')
    .data(links)
    .enter()
    .append('path')
    .attr('class', 'linkPath')
    .attr('fill-opacity', 0)
    .attr('stroke-opacity', 0)
    .attr('id', function (d, i) {return 'linkPath' + d.target.id})
    .style('pointer-events', 'none');

  const linkLables = svg.selectAll('.linkLabel')
    .data(links)
    .enter()
    .append('text')
    .style('pointer-events', 'none')
    .attr('class', 'linkLabel')
    .attr('id', d => `linkLabel-${d.source}-${d.target}`)
    .attr('font-size', 20)
    .attr('font-weight', 'bold')
    .attr('fill', '#000000');

  linkLables.append('textPath')
    .attr('xlink:href', function (d, i) {return '#linkPath' + d.target.id})
    .style('text-anchor', 'middle')
    .style('pointer-events', 'none')
    .attr('startOffset', '50%')
    .text(d => d.relatednessScore > 1 ? `${d.relatednessScore}` : '');


  const node = svg.selectAll('.node')
    .data(nodes);

  const nodeEnter = node.enter()
    .append('g')
    .attr('class', 'node')
    .attr('title', d => `circle${d.id}`)
    .on('click', handleSingleClickNode)
    .on('dblclick', handleDoubleClickNode)
    .call(drag(simulation));

  const circle = nodeEnter.append('circle')
    .attr('class', 'node-circle')
    .attr('stroke', 'black')
    .attr('stroke-width', 2)
    .attr('r', 30)
    .attr('id', d => `circle${d.id}`)
    .attr('fill', '#ef7420');

  d3.select(`#circle${rootNode.id}`)
    .attr('fill', '#ffd100')
    .attr('r', 50)
    .attr('stroke', 'white');

  const text = nodeEnter.append('text')
    .attr('dx', d => -20)
    .attr('id', d => `text${d.id}`)
    .style('font-weight', 'bold')
    .text(d => d.name);

  d3.select(`#text${rootNode.id}`)
    .attr('dx', d => -40);
};


export {
  loadAreas,
  loadDatasetsFromArea,
  loadRelatedDatasets,
  initNodes,
  initLinks,
  generateGraph,
};
